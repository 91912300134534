import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Layout from '../components/Layout'
import './index.scss'

const texts = [
  'in5days',
  'in10hours',
  'in30minutes',
  'in1day',
  'in100days',
  'in45minutes',
]

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active: 0 }
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ active: (this.state.active + 1) % texts.length })
    }, 3000)
  }

  render() {
    return (
      <Layout>
        <div className="background container-fluid">
          <div className="row">
            <div className="col-lg-8 left-column">
              <h1 id="title">
                <a href="https://notnow.co">NotNow.co</a>
              </h1>
            </div>
            <div className="col-lg-4 right-column">
              <section className="text">
                <h2>
                  What if you were able to snooze emails independently of the
                  platform you use?
                </h2>
                <h3>Transfer your emails to our smart addresses:</h3>

                <a
                  className="emails"
                  href={
                    'mailto:' +
                    texts[this.state.active % texts.length] +
                    '@notnow.co'
                  }
                >
                  <div class="animated-words">
                    {texts.map((e, i) => (
                      <span className={this.state.active === i ? 'active' : ''}>
                        {e}
                        @notnow.co
                      </span>
                    ))}
                  </div>
                </a>
                <div className="freeText">
                  Yep, it’s that easy, and guess what, it’s{' '}
                  <strong>free</strong>!
                </div>
                <div className="freeTextExplain">
                  Emails are deleted after being snoozed.
                </div>

                <AniLink
                  cover
                  bg="#ec277e"
                  to="howto"
                  className="btn btn-lg btn-howto"
                >
                  How to use
                </AniLink>
                <br />
                <AniLink
                  cover
                  bg="#ec277e"
                  to="twitter"
                  className="btn btn-lg btn-howto"
                >
                  Also available for Tweets
                </AniLink>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
